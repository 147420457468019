import React from 'react';
function App() {
  return (
    <div className="App">
      <header className="App-header">        
        <p> Product Research and Development (Agile PLM for Devices)</p>       
      </header>
    </div>
  );
}

export default App;
